//判断手机格式
export function getIphoneNumberFormat(val) {
  let phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
  return phone.test(val)
}

//手机格式为3-4-4
export function getIphoneThreeFourFour(val) {
  let value = val.replace(/\D/g, '').substr(0, 11) // 不允许输入非数字字符，超过11位数字截取前11位
  let len = value.length
  if (len > 3 && len < 8) {
    value = value.replace(/^(\d{3})/g, '$1 ')
  } else if (len >= 8) {
    value = value.replace(/^(\d{3})(\d{4})/g, '$1 $2 ')
  }
  return value
}

//判断访问终端
export let browser = {
  versions: function () {
    let u = navigator.userAgent, app = navigator.appVersion
    return {
      trident: u.indexOf('Trident') > -1,
      presto: u.indexOf('Presto') > -1,
      webKit: u.indexOf('AppleWebKit') > -1,
      gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,
      mobile: !!u.match(/AppleWebKit.*Mobile.*/),
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
      android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1,
      iPhone: u.indexOf('iPhone') > -1,
      iPad: u.indexOf('iPad') > -1,
      webApp: u.indexOf('Safari') == -1,
      weixin: u.indexOf('MicroMessenger') > -1,
      qq: u.match(/\sQQ/i) == ' qq'
    }
  }()
}
// 手机系统判断
export function getMobileSystem() {
  var userAgent = navigator.userAgent || navigator.vendor
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone'
  }
  if (/windows/i.test(userAgent)) {
    return 'Windows'
  }

  if (/android/i.test(userAgent)) {
    return 'ANDROID'
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'IOS'
  }
  return 'unknown'
}

export const toDecimal2 = (x, delta = 2) => {
  var f = parseFloat(x)
  if (isNaN(f)) {
    return false
  }
  // var round = Math.round(x * 100) / 100
  var s = x.toString()
  var rs = s.indexOf('.')
  if (rs < 0) {
    rs = s.length
    s += '.'
  }
  while (s.length <= rs + delta) {
    s += '0'
  }
  return s
}
// 分转元
export const regFenToYuan = (fen, delta) => {
  var num = fen
  num = fen * 0.01
  num += ''
  var reg = num.indexOf('.') > -1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g
  num = num.replace(reg, '$1')
  num = toDecimal2(num, delta)
  return num
}
// 元转分
export const regYuanToFen = (yuan) => {
  if (!yuan) {
    return 0
  }
  const index = yuan.toString().indexOf(',')
  if (index !== -1) {
    yuan = Number(yuan.replace(/,/g, ''))
  }
  const arg2 = 100

  let m = 0
  let s1 = yuan.toString()
  let s2 = arg2.toString()

  if (s1.split('.')[1]) {
    m += s1.split('.')[1].length
  }
  if (s2.split('.')[1]) {
    m += s2.split('.')[1].length
  }
  return parseInt((Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)).toFixed(2))
}

export function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(',')
  let mime = arr[0].match(/:(.*?);/)[1]
  let bstr = atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

// uuid生成器
export function uuid() {
  var dt = new Date().getTime()
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16)
  })
  return uuid
}

export function getScrollTop() {
  let de = document.documentElement
  let db = document.body
  let scrollTop = (de && de.scrollTop) || db.scrollTop
  return scrollTop
}
export function setScrollTop(value) {
  let de = document.documentElement
  let db = document.body
  return (de || db).scrollTop = value
}

export function trimCanvas(c) {
  var ctx = c.getContext('2d'),
    copy = document.createElement('canvas').getContext('2d'),
    pixels = ctx.getImageData(0, 0, c.width, c.height),
    l = pixels.data.length,
    i,
    bound = {
      top: null,
      left: null,
      right: null,
      bottom: null
    },
    x, y

  // Iterate over every pixel to find the highest
  // and where it ends on every axis ()
  for (i = 0; i < l; i += 4) {
    if (pixels.data[i + 3] !== 0) {
      x = (i / 4) % c.width
      y = ~~((i / 4) / c.width)

      if (bound.top === null) {
        bound.top = y
      }

      if (bound.left === null) {
        bound.left = x
      } else if (x < bound.left) {
        bound.left = x
      }

      if (bound.right === null) {
        bound.right = x
      } else if (bound.right < x) {
        bound.right = x
      }

      if (bound.bottom === null) {
        bound.bottom = y
      } else if (bound.bottom < y) {
        bound.bottom = y
      }
    }
  }

  var trimHeight = bound.bottom - bound.top,
    trimWidth = bound.right - bound.left,
    trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight)

  copy.canvas.width = trimWidth
  copy.canvas.height = trimHeight
  copy.putImageData(trimmed, 0, 0)

  return copy.canvas
}

export function encodeValue(val) {
  return window.btoa(encodeURIComponent(JSON.stringify(val)))
}
export function decodeValue(val) {
  return JSON.parse(decodeURIComponent(window.atob(val)))
}

//获取当前时间转换为YY-MM
export function yearMonth(d) {
  let date = d ? new Date(d) : new Date()
  let y = date.getFullYear()
  let m = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  return y + '-' + m
}

